import React from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

const API_URL = "https://api.danielfang.org/";

interface State {
  me?: any;
}

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const r = await fetch(API_URL);
    if (r) {
      const data = await r.json();
      this.setState({ me: data.me });
    }
  }

  render() {
    const me = this.state.me;
    let contactNode;
    if (me) {
      contactNode = (
        <div>
          {me.contact.map((c: any) => (
            <a
              key={c.name}
              target="_blank"
              rel="noreferrer"
              href={c.link}
              style={{ marginRight: 5 }}
            >
              {c.name}
            </a>
          ))}
        </div>
      );
    }
    return (
      <Router>
        <header id="nav">
          <ul>
            <li className="nav-link">
              <Link to="">Home</Link>
            </li>
            <li className="nav-link">
              <Link to="projects">Projects</Link>
            </li>
          </ul>
        </header>
        <div id="content">
          <Switch>
            <Route path="/" exact>
              <Home me={me} />
            </Route>
            <Route path="/projects" exact>
              <Projects me={me} />
            </Route>
          </Switch>
        </div>
        <footer>
          <p>
            Site made with{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/danfang/me-api"
            >
              danfang/me-api
            </a>
            . View <a href={API_URL}> API</a>.
          </p>
          {contactNode}
        </footer>
      </Router>
    );
  }
}

class Home extends React.Component<{ me?: any }> {
  constructor(props: {}) {
    super(props);
  }

  render() {
    var me = this.props.me;
    let description;
    if (!me) {
      description = (
        <h3>
          <a target="_blank" rel="noreferrer" href="https://api.danielfang.org">
            api.danielfang.org
          </a>
          <span> is currently unavailable!</span>
        </h3>
      );
    } else {
      description = (
        <div>
          <p>{me.bio}</p>
          <p>
            {"Currently working on "}
            <a target="_blank" rel="noreferrer" href={me.currentWork.url}>
              {me.currentWork.name}
            </a>
            .
          </p>
        </div>
      );
    }
    return (
      <div>
        <div>
          <h1>{me?.name || "Daniel Fang"}</h1>
          {description}
        </div>
      </div>
    );
  }
}

function Projects(props: { me?: any }) {
  var me = props.me;
  if (!me) {
    return null;
  }
  return (
    <div>
      {me.projects.map((p: any) => (
        <Project key={p.title} project={p} />
      ))}
    </div>
  );
}

function Project(props: { project: any }) {
  var project = props.project;
  return (
    <div>
      <h2>
        {project.url ? (
          <a href={project.url}>{project.title}</a>
        ) : (
          project.title
        )}
      </h2>
      <p>{project.date}</p>
      <p>{project.description}</p>
    </div>
  );
}

export default App;
